// temporary hack to increase the z-index to show/allow pointer events on the modal close buttons
// studio modals will be migrated to use new DIY-UI library components by end of Q4
// at which point this can be removed https://vistaprint.atlassian.net/wiki/spaces/AE/pages/3760099614/Other+Studio+Updates
.swan-modal-dialog-close-button {
    z-index: 10 !important;
}

/* This is targeting the chat window so that it shows up over the studio live overlay */
.showDockableContainer {
    z-index: calc(infinity) !important;
}
